<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<build_nav :site="site" :preview="staging" :wpurl="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-100 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						<div class="container">

							<div class="row justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div v-if="error && error.message" class="alert alert-danger d-flex align-items-center mb-4">
										<div class="me-2 d-flex align-items-center">
											<span class="material-icons-outlined">warning</span>
										</div>
										<div class="flex-grow-1">
											<p class="small mb-0">
												{{ error.message }}

												<span v-if="error.errors && error.errors[0]">({{ error.errors[0].message }})</span>
											</p>
										</div>
									</div>

									<div v-if="success" class="alert alert-success d-flex align-items-center mb-4">
										<div class="me-2 d-flex align-items-center">
											<span class="material-icons-outlined">check</span>
										</div>
										<div class="flex-grow-1">
											<p class="small mb-0">
												{{ success }}
											</p>
										</div>
									</div>

									<div v-if="site.settings['vc_repo_init']" class="mb-4 d-flex align-items-center justify-content-between">
										<btn_submit 
											:label="'Force pull'" 
											icon="arrow_back"
											btn_class="btn btn-primary"  
											:icon_before="true" 
											:loading="loading" 
											v-on:click="git_pull"
										/>
										<btn_submit 
											:label="'Force push'" 
											icon="arrow_forward" 
											btn_class="btn btn-primary" 
											:icon_after="true" 
											:loading="loading" 
											v-on:click="git_push"
										/>
									</div>

									<div class="accordion" id="steps">
										<div class="accordion-item">
											<h2 class="accordion-header" id="repo-details-heading">
												<button class="accordion-button fw-bold text-primary" :class="site['settings']['vc_is_ready'] ? 'collapsed' : ''" type="button" data-bs-toggle="collapse" data-bs-target="#repo-details" aria-expanded="true" aria-controls="repo-details">
												<span class="vc-step">1</span> Add repository details
												</button>
											</h2>
											<div id="repo-details" class="accordion-collapse collapse" :class="site['settings']['vc_is_ready'] ? '' : 'show'" aria-labelledby="repo-details-heading" data-bs-parent="#steps">
												<div class="accordion-body">
													
													<label for="vc_repo_name" class="form-label fw-bold">Repository type *</label>
													<div class="list-group mb-3">
														<div class="d-flex align-items-start list-group-item position-relative" :class="type == 'github' ? 'text-success border-success bg-success bg-opacity-25' : ''">
															<div class="flex-grow-1 d-flex align-items-center">
																<img src="@/assets/img/github.svg" height="30" class="d-block me-3">
																<div>
																	<div class="flex-grow-1 me-4">
																		<p class="fw-bold mb-0 text-dark">Github</p>
																	</div>
																</div>
															</div>
															<span v-if="type == 'github'" class="material-icons-outlined">check</span>
															<button v-on:click="type = 'github'" class="stretched-link bg-transparent border-0"></button>
														</div>
														<!-- <div class="d-flex align-items-start list-group-item position-relative" :class="type == 'bitbucket' ? 'text-success border-success bg-success bg-opacity-25' : ''">
															<div class="flex-grow-1 d-flex align-items-center">
																<img src="@/assets/img/github.svg" height="30" class="d-block me-3">
																<div>
																	<div class="flex-grow-1 me-4">
																		<p class="fw-bold mb-0 text-dark">BitBucket</p>
																	</div>
																</div>
															</div>
															<span v-if="type == 'bitbucket'" class="material-icons-outlined">check</span>
															<button v-on:click="type = 'bitbucket'" class="stretched-link bg-transparent border-0"></button>
														</div> -->
													</div>

													<form_control
														label="Repository name"
														type="text"
														placeholder="eg: areoi/my-new-repo"
														name="vc_repo_name"
														help="Add your repository path. This will be user/repo-name or org/repo-name. Make sure your repository is completely empty."
														:required="true"
														:error="error"
														v-model="site.settings['vc_repo_name']"
													/>

													<form_control
														label="Repository branch"
														type="text"
														placeholder="eg: staging"
														name="vc_repo_branch"
														help="Specify which branch you would like to sync. If the branch doesn't already exist, we'll create it on our initial push."
														:required="true"
														:error="error"
														v-model="site.settings['vc_repo_branch']"
													/>

													<btn_submit 
														:label="'Save repository details'" 
														icon="check"  
														:icon_after="true" 
														:loading="loading" 
														v-on:click="submit"
													/>
												</div>
											</div>
										</div>
										<div v-if="site['settings']['vc_is_ready']" class="accordion-item">
											<h2 class="accordion-header" id="deploy-key-header">
												<button class="accordion-button collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#deploy-key" aria-expanded="false" aria-controls="deploy-key">
												<span class="vc-step">2</span> Add deploy key to repository
												</button>
											</h2>
											<div id="deploy-key" class="accordion-collapse collapse" aria-labelledby="deploy-key-header" data-bs-parent="#steps">
												<div class="accordion-body">
													<div class="alert alert-info mb-3 d-block small">
														When making a deployment, you must add the following SSH key to your source control provider. This gives the platform permissiosn to push and pull to your repository.
													</div>

													<div class="list-group">
														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Title</p>
															AREOI Platform
														</div>

														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Key</p>
															<div class="bg-light border rounded p-3 small"><code>{{ site.settings['vc_deploy_key'] }}</code></div>
														</div>

														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Allow write access</p>
															True
														</div>
													</div>
												</div>
											</div>
										</div>

										<div v-if="site['settings']['vc_is_ready']" class="accordion-item">
											<h2 class="accordion-header" id="webhook-header">
												<button class="accordion-button collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#webhook" aria-expanded="false" aria-controls="webhook">
												<span class="vc-step">3</span> Add webhook to repository
												</button>
											</h2>
											<div id="webhook" class="accordion-collapse collapse" aria-labelledby="webhook-header" data-bs-parent="#steps">
												<div class="accordion-body">
													<div class="alert alert-info mb-3 d-block small">
														For two way sync you need to add a webhook to your repository. This tells the platform when changes are waiting to be pulled.
													</div>

													<div class="list-group">
														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Payload URL</p>
															<div class="bg-light border rounded p-3 small"><code>{{ webhook_url }}</code></div>
														</div>

														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Content type</p>
															application/json
														</div>

														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Secret</p>
															<div class="bg-light border rounded p-3 small"><code>{{ site.settings['vc_webhook_secret'] }}</code></div>
														</div>

														<div class="list-group-item">
															<p class="mb-0 fw-bold mb-2">Events</p>
															Just the push event.
														</div>
													</div>

												</div>
											</div>
										</div>

										<div v-if="site['settings']['vc_is_ready']" class="accordion-item">
											<h2 class="accordion-header" id="push-header">
												<button class="accordion-button collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#push" aria-expanded="false" aria-controls="push">
												<span class="vc-step">4</span> Push intial commit
												<span v-if="!site.settings['vc_repo_init']" class="ms-3 badge bg-warning">Needs action</span>
												</button>
											</h2>
											<div id="push" class="accordion-collapse collapse" aria-labelledby="push-header" data-bs-parent="#steps">
												<div class="accordion-body">
													
													<p class="d-flex align-items-center mb-3" :class="site.settings['vc_repo_name'] ? 'text-success' : 'text-danger'">
														<span class="material-icons-outlined me-2">{{ site.settings['vc_repo_name'] ? 'check' : 'close' }}</span>
														Added repository name
													</p>

													<p class="d-flex align-items-center mb-3" :class="site.settings['vc_repo_branch'] ? 'text-success' : 'text-danger'">
														<span class="material-icons-outlined me-2">{{ site.settings['vc_repo_branch'] ? 'check' : 'close' }}</span>
														Added repository branch
													</p>

													<p class="d-flex align-items-center mb-3" :class="site.settings['vc_repo_init'] ? 'text-success' : 'text-warning'">
														<span class="material-icons-outlined me-2">{{ site.settings['vc_repo_init'] ? 'check' : 'info' }}</span>
														Have you added the deploy key to your repository?
													</p>

													<p class="d-flex align-items-center mb-3" :class="site.settings['vc_repo_init'] ? 'text-success' : 'text-warning'">
														<span class="material-icons-outlined me-2">{{ site.settings['vc_repo_init'] ? 'check' : 'info' }}</span>
														Have you added the webhook to your repository?
													</p>

													<p v-if="site.settings['vc_repo_init']" class="d-flex align-items-center mb-3 text-success">
														<span class="material-icons-outlined me-2">check</span>
														Pushed initial commit
													</p>

													<btn_submit 
														v-else
														:label="'Initial push to repository'" 
														icon="check"  
														:icon_after="true" 
														:loading="loading" 
														v-on:click="init_repo"
														:disabled="!site.settings['vc_repo_name'] || !site.settings['vc_repo_branch']"
													/>
												</div>
											</div>
										</div>

										<div v-if="site['settings']['vc_is_ready']" class="accordion-item">
											<h2 class="accordion-header" id="local-header">
												<button class="accordion-button collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#local" aria-expanded="false" aria-controls="local">
												<span class="vc-step">5</span> Setup your site locally
												</button>
											</h2>
											<div id="local" class="accordion-collapse collapse" aria-labelledby="local-header" data-bs-parent="#steps">
												<div class="accordion-body">

													<div class="accordion" id="instructions">

														<div class="accordion-item">
															<h2 class="accordion-header" id="step-2-header">
																<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-2" aria-expanded="true" aria-controls="step-2">
																	1. Clone repo from connected account
																</button>
															</h2>
															<div id="step-2" class="accordion-collapse collapse" aria-labelledby="step-2" data-bs-parent="#instructions">
																<div class="accordion-body">
																	Clone the newly created repo to your local machine:<br> <code>git clone git@github.com:{{ site.settings['vc_repo_name'] }}.git</code>
																</div>
															</div>
														</div>

														<div class="accordion-item">
															<h2 class="accordion-header" id="step-3-header">
																<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-3" aria-expanded="false" aria-controls="step-3">
																	2. Setup database
																</button>
															</h2>
															<div id="step-3" class="accordion-collapse collapse" aria-labelledby="step-3" data-bs-parent="#instructions">
																<div class="accordion-body">

																	<a v-if="!auth_user.on_trial" :href="download" target="_blank" class="btn btn-lg btn-secondary w-100 d-flex align-items-center justify-content-between">
																		Download latest database

																		<span class="material-icons-outlined">download</span>
																	</a>
																	<div v-else>
																		<button class="btn btn-lg btn-secondary w-100 d-flex align-items-center justify-content-between disabled">
																			Download latest database

																			<span class="material-icons-outlined">download</span>
																		</button>
																		<p class="small mt-2 mb-0">You can't download databases while on your free trial</p>
																	</div>

																	<p class="h6 mb-2 mt-4">Create database</p>
																	<p>Open up your database manager. Create a new database named <code>{{ login.database }}</code> along with a user. Make a note of your username and password as you will need these later.</p>

																	<p class="h6 mb-2">Import data</p>
																	<p>Once you have created your database you need to import the generated .sql file (<code>/{{ login.database }}.sql</code>). You can do this via phpmyadmin or your usual database tool. If using phpmyadmin select the database you created then click Import and choose the .sql file from your local machine then click Go. Ignore any messages that popup and proceed to import the database.</p>

																	<p class="h6 mb-2">Replace development urls</p>
																	<p>Once the database has been imported you need to run a query to change any refernce to the development url to your new url. You can do this by running the below query (make sure you replace https://mydomain.local with your actual url).</p>
																	<p class="p-2 w-100 overflow-auto" style="background: #f2f2f2;"><code style="display: block; white-space: pre;">
																		UPDATE {{ login.database }}_options SET option_value = replace(option_value, '{{ preview }}', 'https://mydomain.local') WHERE option_name = 'home' OR option_name = 'siteurl';
																		<br><br>
																		UPDATE {{ login.database }}_posts SET guid = REPLACE(guid, '{{ preview }}','https://mydomain.local');
																		<br><br>
																		UPDATE {{ login.database }}_posts SET post_content = REPLACE(post_content, '{{ preview }}', 'https://mydomain.local');
																		<br><br>
																		UPDATE {{ login.database }}_postmeta SET meta_value = REPLACE(meta_value,'{{ preview }}','https://mydomain.local');
																	</code></p>

																	<p class="h6 mb-2">Update wp-config.php</p>
																	<p class="mb-0">Finally you need to update your <code>wp-config.php</code> file with your new database credentials. Within the repo there is a <code>wp-config-example.php</code> file. Open the file and  replace <code>username_here</code> with your database username. Then replace <code>password_here</code> with your database password and finally rename the file to <code>wp-config.php</code>.</p>
																</div>
															</div>
														</div>

														<div class="accordion-item">
															<h2 class="accordion-header" id="step-4-header">
																<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-4" aria-expanded="false" aria-controls="step-4">
																	3. User management
																</button>
															</h2>
															<div id="step-4" class="accordion-collapse collapse" aria-labelledby="step-4" data-bs-parent="#instructions">
																<div class="accordion-body">
																	<div v-if="login.user">
																		<p>As part of the build we have setup a temporary admin user on the WordPress website. You should use this to login and manage your WordPress admin.</p>
																		<p>You can access the admin with the below details (change https://yourdomain.local to your actual domain):</p>
																		<p class="mb-0 p-2" style="background: #f2f2f2;">
																			<code>
																				https://yourdomain.local/wp-admin<br>
																				U: {{ login.user }}<br>
																				P: {{ login.password }}
																			</code>
																		</p>
																	</div>
																</div>
															</div>
														</div>

														<div class="accordion-item">
															<h2 class="accordion-header" id="step-5-header">
																<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-5" aria-expanded="false" aria-controls="step-5">
																	4. All set!
																</button>
															</h2>
															<div id="step-5" class="accordion-collapse collapse" aria-labelledby="step-5" data-bs-parent="#instructions">
																<div class="accordion-body">
																	<div class="alert alert-success mb-0">
																		<p class="h6 mb-2">Woohoo</p>
																		<p class="mb-0">After following the above instructions you should be good to go. If you are struggling to get your website setup then please don't hesitate to get in touch with our support team.</p>
																	</div>
																	
																</div>
															</div>
														</div>

													</div>

												</div>
											</div>
										</div>
									</div>

								</div>

							</div>	

						</div>

					</div>			

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import build_nav from '@/components/build_nav'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'site.version-control',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		build_nav,
		form_control,
		btn_submit
	},

	data() {
		return {
			loading: false,
			error: {},
			success: '',
			site: null,
			version: 0,
			type: 'github',
			login: {
				user: null,
				password:null,
				database: null
			},
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/storage/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
		
		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		download()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/download/'
			}

			if ( this.site ) {
				url += this.site.slug + '/' + this.site.id + '/database'
			}

			return url
		},

		webhook_url()
		{
			return 'https://api.areoi.io/api/sync/' + this.site.workspace_id + '/' + this.site.id + '/pull';
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_wp_login()
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = ''
		},

		async get_wp_login()
		{			
			this.reset_error()

			await site_service.wp_login( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.login = response.data

			}).catch((error) => {
				this.error = error
			})
		},

		submit()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'vc'

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {
				this.loading = false
			});
		},

		init_repo()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'vc'

			site_service.init_repo( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {
				this.loading = false
			});
		},

		git_push()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'vc'

			site_service.git_push( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Pushed successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {
				this.loading = false
			});
		},

		git_pull()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'vc'

			site_service.git_pull( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Pulled successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {
				this.loading = false
			});
		},

		disable()
		{
			this.loading = true

			this.reset_error()

			this.site.question = 'vc'

			this.site.settings['vc_type'] = null
			this.site.settings['vc_token'] = null

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				this.get_github_url()

			}).catch( ( error ) => {

				this.error = error

				this.loading = false

			})
		},
	}
}
</script>