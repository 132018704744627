<template>
	
	<div v-if="site.settings && (site.settings['build_complete'] || site.settings['is_build_complete'])">

		<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">

			<div class="d-flex align-items-center justify-content-between">

				<div class="d-flex align-items-center">
					<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
						{{ limitString( site.name, 20 ) }}
					</div>

					<router-link :to="{ name: 'site.build' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.build' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">smart_display</span>
						Preview
					</router-link>

					<div class="btn-group dropdown">
						<button 
						class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 dropdown-toggle" 
						:class="( ['site.build.content.pages', 'site.build.content.menus', 'site.build.content.header', 'site.build.content.footer'].includes( $route.name ) ? 'border-primary' : 'border-light' )" 
						type="button" 
						id="globals-dropdown" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined me-2 d-none d-xxl-block">call_to_action</span>
							Content
						</button>
						<ul class="dropdown-menu" aria-labelledby="globals-dropdown">

							<li>
								<router-link 
								:to="{ name: 'site.build.content.pages' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.content.pages' ? 'active' : '' )"
								>
									Pages
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.menus' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.content.menus' ? 'active' : '' )"
								>
									Menus
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.header' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.content.header' ? 'active' : '' )"
								>
									Header
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.footer' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.content.footer' ? 'active' : '' )"
								>
									Footer
								</router-link>
							</li>

						</ul>
					</div>

					<div class="btn-group dropdown">
						<button 
						class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 dropdown-toggle" 
						:class="( $route.name.includes( '-template' ) ? 'border-primary' : 'border-light' )" 
						type="button" 
						id="globals-dropdown" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined me-2 d-none d-xxl-block">space_dashboard</span>
							Templates
						</button>
						<ul class="dropdown-menu" aria-labelledby="globals-dropdown">

							<li>
								<router-link 
								:to="{ name: 'site.build.content.post-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.build.content.post-template' ? 'active' : '' )"
								>
									Posts
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.search-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.build.content.search-template' ? 'active' : '' )"
								>
									Search
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.archive-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.build.content.archive-template' ? 'active' : '' )"
								>
									Archive
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.content.notfound-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.build.content.notfound-template' ? 'active' : '' )"
								>
									404
								</router-link>
							</li>

						</ul>
					</div>

					<div class="btn-group dropdown">
						<button 
						class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 dropdown-toggle v-step-content" 
						:class="( $route.name.includes( 'site.build.extend' ) ? 'border-primary' : 'border-light' )" 
						type="button" 
						id="globals-dropdown" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined me-2 d-none d-xxl-block">code</span>
							Extend
						</button>
						<ul class="dropdown-menu" aria-labelledby="globals-dropdown">

							<li>
								<router-link 
								:to="{ name: 'site.build.extend.version-control' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.extend.version-control' ? 'active' : '' )"
								>
									Version Control
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.extend.theme' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.extend.theme' ? 'active' : '' )"
								>
									Edit Theme
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.extend.plugins' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.extend.plugins' ? 'active' : '' )"
								>
									Edit Plugins
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.build.extend.install-plugins' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.build.extend.install-plugins' ? 'active' : '' )"
								>
									Install Plugins
								</router-link>
							</li>
						</ul>
					</div>

					<a :href="wpurl + '/wp-admin/'" target="_blank" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">open_in_new</span>
						Edit in WP
					</a>
					<a :href="preview" target="_blank" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">language</span>
						Staging
					</a>
				</div>

			</div>

			<sub_nav :site="site" />
		</div>

	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import sub_nav from '@/components/sub_nav'

export default {
	name: 'components.build_nav',
	props: {
		site: Object,
		preview: String,
		wpurl: String,
		is_back: Boolean,
		show_share: Boolean,
		modelValue: [String, Number, Object, Boolean]
	},
	components: {
		sub_nav
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},
	},
	methods: {
		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>

</style>
