<template>
	
	<button 
		:disabled="loading || disabled" 
		class="d-inline-flex justify-content-between align-items-center" 
		:class="( !btn_class ? 'btn btn-lg btn-outline-secondary w-100' : btn_class )"
	>

		<span v-if="icon_before" class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
			<span v-if="!loading" class="material-icons-outlined me-2">{{ ( icon ? icon : 'east' ) }}</span>

			<span v-else class="d-flex align-items-center justify-content-center">
				<span class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
			</span>
		</span>

		{{ label }}

		<span v-if="!icon_before" class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
			<span v-if="!loading" class="material-icons-outlined ms-2">{{ ( icon ? icon : 'east' ) }}</span>

			<span v-else class="d-flex align-items-center justify-content-center">
				<span class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
			</span>
		</span>
	</button>

</template>

<script>
export default {
	name: 'components.btn_submit',
	props: {
		loading: Boolean,
		disabled: Boolean,
		label: String,
		icon: String,
		btn_class: String,
		icon_before: Boolean
	}
}
</script>

<style>

</style>
